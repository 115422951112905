'use strict'
const urlUtils = require('../../urlUtils')

function wixImageWrapper(services, environmentConsts) {
    const $ = require('zepto')

    class WixImage extends HTMLElement {
        constructor() { // eslint-disable-line no-useless-constructor
            super()
        }

        layoutImage() {
            const nodesMap = {}
            const measureMap = {
                height: {},
                width: {},
                custom: {},
                absoluteTop: {}
            }

            const imageId = this.getAttribute('id')
            nodesMap[imageId] = this
            const imageInfo = $(this).data('imageInfo')
            const parentStructureInfo = {id: imageInfo.containerId}
            const siteData = {
                currentUrl: environmentConsts.currentUrl,
                imageLoader: services.imageLoader,
                isViewerMode: () => environmentConsts.isViewerMode,
                getMediaFullStaticUrl: imgRelativeUrl =>
                    urlUtils.getMediaUrlByContext(imgRelativeUrl, environmentConsts.staticMediaUrl, environmentConsts.mediaRootUrl),
                mobile: {
                    getDevicePixelRatio: () => environmentConsts.devicePixelRatio
                }
            }

            services.mutationService.measure(() => {
                services.imageLayout.measure(imageId, measureMap, nodesMap, parentStructureInfo)
            })

            services.mutationService.mutate(() => {
                const patchers = services.imageLayout.createDOMPatchers(nodesMap)
                services.imageLayout.patch(imageId, patchers, measureMap, siteData, imageInfo.imageData, null, imageInfo.alignType, services.biService)
            })
        }

        connectedCallback() {
            this.layoutImage()
        }

        attributeChangedCallback(name, oldValue) {
            if (oldValue) {
                this.layoutImage()
            }
        }

        static get observedAttributes() { return ['data-image-info'] }
    }

    return WixImage
}

module.exports = wixImageWrapper