'use strict'
const _ = require('lodash')
const wixImage = require('./wixImage/wixImage')
const imageServices = {}

function defineWixImage(services, environmentConsts) {
    require('./native-shim')

    _.assign(imageServices, services)

    if (_.isUndefined(customElements.get('wix-image'))) {
        const WixImageClass = wixImage(imageServices, environmentConsts)
        customElements.define('wix-image', WixImageClass)
    }
}

module.exports = {
    defineWixImage
}